/** 常用色值 **/
/** 常用字体大小 **/
/** load **/
.ajax-loading {
  display: none;
}
.ajax-loading .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  color: #fff;
}
.ajax-loading .loading img {
  width: 32px;
  vertical-align: middle;
}
.ajax-loading .loading span {
  margin-left: 12px;
}
.ajax-loading .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  background: #ffffff;
  opacity: 0.1;
}
/****/
.container .nav-left {
  background-color: #001529;
  color: #ffffff;
  height: calc(100vh);
}
.container .main {
  height: calc(100vh);
  background-color: #f1f3f5;
}
.container .content {
  position: relative;
  padding: 20px;
}
.content-wrap {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-top: -3px;
}
.content-wrap .ant-table-wrapper {
  margin-left: -1px;
  margin-right: -2px;
}
.operate-wrap button {
  margin-right: 10px;
}
